@import "~normalize-scss/sass/normalize";

@media only screen {
  // material theme
  @import '~@angular/material/theming';
  @import "mixins";

  @include mat-core();
  $app-accent: mat-palette($mat-deep-orange);
  $app-primary: mat-palette($mat-blue-gray);
  $app-warn: mat-palette($mat-red);
  $app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
  $boxShadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  @include angular-material-theme($app-theme);

  // global styles
  mat-sidenav {
    .mat-icon {
      margin-right: 1rem;
    }
  }

  .mat-card-title {
    font-size: 1.25rem !important;
  }

  .mat-card-header-text {
    width: 100%;
  }

  .full-width {
    width: 100%;
  }

  html,
  body,
  app-root,
  app-root > section,
  main {
    display: block;
    min-height: 100%;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    font-size: 100%;
  }

  @mixin fullscreen-background {
    display: block;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mat-sidenav-container,
  .mat-sidenav-content {
    min-height: calc(100% - 64px);
    height: calc(100% - 64px);
  }

  .mat-sidenav {
    box-shadow: $boxShadow;
    min-height: 100%;
  }

  main {
    min-height: 100%;
    overflow-x: hidden;
    position: relative;
  }

  body,
  a {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }

  a {
    color: map-get($app-primary, 'default');
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
    &.mat-button {
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  main > * {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mat-drawer-container {
    padding-top: 1rem;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:last-of-type {
      padding-bottom: 3rem;
    }

    @media only screen and (max-width: 600px) {
      padding-right: .5rem;
      padding-top: .5rem;
    }
  }

  // bugfixes/adaptions

  // sort column needs extra height
  .mat-header-cell,
  .mat-cell {
    min-height: 50px;
    line-height: 2.1rem;
    padding-top: 1rem;
    cursor: pointer;
  }

  .mat-header-row, .mat-row {
    padding-left: 0 !important;
  }

  .breadcrumb {
    mat-icon {
      margin-right: .5rem;
    }
    &-item {
      padding-left: 2px !important;
      margin-right: 2px !important;
    }

    &-separator {
      display: inline-block;
      line-height: 14px;
      margin: 0 !important;
      height: 14px;
      mat-icon {
        position: relative;
        top: -6px;
      }
    }
  }

  .mat-button-wrapper,
  .text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;
    display: block;
  }

  .text-center {
    text-align: center;
  }

  // .mat-success color
  .mat-progress-bar.mat-success .mat-progress-bar-fill::after {
    background-color: map-get($mat-green, 600) !important;
  }

  .mat-chip.mat-chip-selected:not(.mat-basic-chip).mat-success {
    background-color: map-get($mat-green, 500) !important;
    color: #fff !important;
  }

  [mat-raised-button].mat-success,
  [mat-fab].mat-success,
  [mat-mini-fab].mat-success {
    background-color: map-get($mat-green, 500) !important;
    color: #fff !important;
  }

  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .content {
    overflow-x: hidden;
    min-height: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    @include desktop {
      width: calc(100% - 2rem);
    }
    .mat-card {
      overflow-x: hidden;
    }
  }

  @include phone {
    .mat-cell:first-child,
    .mat-footer-cell:first-child,
    .mat-header-cell:first-child {
      padding-left: 8px !important;
    }

    .mat-header-cell, .mat-cell {
      padding-top: .5rem;
      padding-bottom: .5rem;
      line-height: 44px;
    }
  }

  .mat-menu-panel {
    padding-left: .5rem;
    padding-right: .5rem;
    .mat-button,
    .mat-raised-button {
      margin-top: .5rem;
    }
  }

  .mat-cell .mat-mini-fab {
    @include minTablet() {
      position: relative;
      top: -10px;
    }
  }

  dropzone > .dropzone.dz-wrapper .dz-message {
    height: 120px;
  }

  .mat-mini-fab .mat-button-wrapper {
    padding: 0 8px !important;
    display: block !important;
    line-height: 36px !important;
  }
}


@media print {
  @page {
    size: landscape;
  }
  body {
    padding: .5rem;
  }

  html,
  body,
  mat-sidenav-content,
  main > * {
    position: static !important;
    margin-left: 0 !important;
    overflow: auto !important;
    min-height: 100% !important;
    height: auto !important;
    width: 100% !important;
    max-width: initial !important;
  }

  .mat-drawer-container {
    overflow: auto !important;
  }

  .noprint {
    display: none !important;
  }


  .mat-sidenav {
    display: none !important;
  }

  .mat-sidenav-content {
    margin-left: 0 !important;
  }

  .cdk-overlay-container,
  .cdk-live-announcer-element,
  .noprint {
    display: none !important;
  }
}
