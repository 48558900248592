$tablet-width: (37.5*16)*1px;
$desktop-width: (60*16)*1px;
$large-desktop-width: (80*16)*1px;

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin minTablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin maxTablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin largeDesktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}
